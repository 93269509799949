<template>
    <table class="upload">
        <tr>
            <th>Ini file</th>
            <td>
                <input type="file" class="inputfile" name="inifile"
                id="inifileinput"
                accept=".ini"
                @change="gotfile('ini', $event);">
                <div class="uploadarea">
                    <div class="uploadarea-text">
                        {{statustext('ini')}}
                    </div>
                </div>
            </td>
            <td id="inifileok"></td>
        </tr>
        <tr>
            <th>Ra1/2 file</th>
            <td>
                <input type="file" class="inputfile" name="rafile"
                id="rafileinput"
                accept=".ra1,.ra2"
                @change="gotfile('ra', $event);">
                <div class="uploadarea">
                    <div class="uploadarea-text">
                        {{statustext('ra')}}
                    </div>
                </div>
            </td>
            <td id="rafileok"></td>
        </tr>
        <tr>
            <th>Reference?</th>
            <td><input v-model="isref" type="checkbox" name="isref"></td>
        </tr>
        <tr>
            <th>Data complete?</th>
            <td>
                <button :disabled="!bothFilesOk" type="button" class="filesok" v-on:click="clickHandler">
                    {{buttonText}}
                </button>
                <button :class="resetbuttonClass" v-on:click="resetHandler">
                    Reset
                </button>
            </td>
        </tr>
    </table>
</template>

<script>
import {API} from '../API.js';

export default {
    name: 'UploadReference',
    data() {
        return {
            raStatus: {
                progress: 0,
                filename: "",
            },
            iniStatus: {
                progress: 0,
                filename: "",
            },
            isref: false,
            buttonText: "Confirm",
        }
    },
    computed: {
        isIniInitial() { return this.iniStatus.progress === 0; },
        isRaInitial()  { return this.raStatus.progress  === 0; },
        bothFilesOk() {
            return this.iniStatus.progress == 100 && this.raStatus.progress == 100;
        },
        resetbuttonClass() { return this.buttonText === "Confirm" ? "resethidden" : "resetshown" }
    },
    methods: {
        gotfile(fileType, event) {
            var filelist = event.target.files;
            if (filelist.length == 0) {
                console.log("type "+fileType+" has no files");
                this.setProgressForType(fileType, 0);
                return;
            }
            var filename = filelist.item(0).name;
            console.log("file "+fileType+" is " + filename + " now");
            this.getStatusForType(fileType).filename = filename;
            API.upload("measurements/upload",
             (eventType, event) => this.uploadProgress(eventType, fileType, event),
              filelist.item(0));
            this.setProgressForType(fileType, 1);
            this.buttonText = "Confirm";
        },
        statustext(type) {
            var status = this.getStatusForType(type);
            switch (status.progress) {
                case 0: return "Drag a file here or click to choose"; 
                case 100: return "Upload of " + status.filename + " complete";
            }
            return "Uploading "+status.filename+" "+status.progress+" %"; // +": "+status.sent+" of "+status.total;
            
        },
        setProgressForType(type, status) {
            this.getStatusForType(type).progress = status;
        },
        getStatusForType(type) {
            switch (type) {
                case 'ini': return this.iniStatus;
                case 'ra' : return this.raStatus;
            }
        },
        uploadProgress(eventType, fileType, event) {
            var status = this.getStatusForType(fileType);
            switch (eventType) {
                case 'progress': status.progress = Math.floor(event.loaded * 100 / event.total); break;
                case 'load':     status.progress = 100; break;
                case 'error':    status.progress = 101; break;
                case 'abort':    status.progress = 102; break;
            }
            console.log("event of "+eventType+" for file type "+fileType, event);
        },
        clickHandler() {
            console.log("click");
            API.transact("measurements/confirmra?ref="+this.isref
                +"&ra="+this.raStatus.filename
                +"&ini="+this.iniStatus.filename, this.sentDone, null);
        },
        sentDone(ok, result) {
            if (result.status == 0) {
                this.buttonText = result.result;
            } else {
                this.buttonText = result.error;
            }
        },
        resetHandler() {
            document.getElementById("inifileinput").value = null;
            document.getElementById("rafileinput").value = null;
            this.buttonText = "Confirm";
            this.raStatus.progress = 0;
            this.iniStatus.progress = 0;
        }
    }
}
</script>

<style scoped>
th {
    text-align: left;
    width: 130px;
}

tr {
    min-height: 50px;
}

.uploadarea {
    outline: 2px dashed grey;
    outline-offset: -10px;
    text-align: center;
    vertical-align: middle;
    background-color:aqua;
    margin: 0;
    width: 500px;
    height: 100px;
}

.uploadarea:hover {
    background: cyan;
}

.uploadarea-text {
    width: 420px;
    height: 40px;
    padding: 40px;
}

.inputfile {
    opacity: 0;
    width: 500px;
    height: 100px;
    position: absolute;
    cursor: pointer;
}

button.filesok {
    width: 200px;
    height: 40px;
}

button.resethidden {
    width: 100px;
    height: 40px;
    visibility: hidden;
}

button.resetshown {
    width: 100px;
    height: 40px;
    visibility: visible;
}
</style>