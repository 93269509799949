<template>
    <div class="page">
        <div>Change Passowrd for User: {{ user.username }}</div>
        <form>
            <table>
                <tr>
                    <td><label class="label" for="name">Password: </label></td>
                    <td><input class="input" type="password" id="password" v-model="user.password"></td>
                </tr>
                <tr>
                    <td><label class="label" for="name">Repeat Password: </label></td>
                    <td><input class="input" type="password" id="passwordRepeat" v-model="passwordRepeat"></td>
                </tr>
            </table>

            <div class="row action" @click="editUser()">Submit</div>
            <div class="row">
                {{this.apierror}}
            </div>

        </form>
    </div>
</template>
<script>

    import { API } from '../API.js'

    export default {
        name: 'User',
        props: {
        },
        data() {
            return {
                user: Object,
                passwordRepeat: "",
                apierror: ""
            }
        },
        methods: {
            editUser() {
                if (this.passwordRepeat === this.user.password) {
                    this.submitChanges()
                }
                else {
                    this.apierror = "Passwords do not match, try again!"
                }
            },
            UserLoaded(status, object) {
                this.user = object.result;
                this.user.password = ""
                if (!status) {
                    alert("Unable to load user for some reason?");
                }
            },
            PasswordChanged(status, object) {
                if (!status || !object.result.username) {
                    alert("Unable to change password of user for some reason?");
                }
                this.$router.push("/user/");
            },
            submitChanges() {
                API.transact("/user/" + this.$route.params.username + "/changepw", this.PasswordChanged, this.user, "PUT");
            }
        },
        mounted() {
            API.transact("/user/" + this.$route.params.username, this.UserLoaded);
        },
    }
</script>

<style scoped>
th,td {
    text-align: left;
    font-size: 15px;
    color:black;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

div.page {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
}

div.row {
    margin: auto;
    padding-top: 10px;
    height: 30px;
}

.label { width: 100px; }

.input { width: 200px; }

div.action {
    text-align: center;
    color: #2c3e50;
    background-color: azure;
    border: 1px solid black;
    border-radius: 4px;
    margin-right: 2px;
    padding: 4px;
    display: inline-block;
    height: 20px;
    width: 100px;
    cursor:pointer;
}
</style>
