<template>
    <div class="page">
        <div class="row">
        New installation ID:  <input maxlength=4 v-model="newid" v-on:input="idchange"/>
        </div>
        <div class="row">
        {{displayInstHelp()}}
        </div>
        <div class="row action" @click="submitcreate()">Create</div>
        <div class="row">
        {{this.apierror}}
        </div>
    </div>
</template>

<script>
import { API } from '../API.js'

export default {
    name: "InstallationCreate",
    props: {
    },
    data() {
      return {
        newid: "????",
        apierror: "",
        installations: [],
      }
    },
    methods: {
      idchange() {
      },
      submitcreate() {
        var selected = this.installations.filter( (m) => Number(m.installationId) == this.newid);
        if (selected.length != 0) {
          this.apierror = "Installation "+this.newid + " already exists";
          return;
        }
        API.transact("installations/"+this.newid+"/create", this.createCallback);
      },

      createCallback(status, result) {
        if (status) {
            this.apierror = "";
            var id  = "" + this.newid;
            while (id.length < 4) {
                id = "0" + id;
            }
            this.$router.push("/installations/" + id + "/Customer");
        } else {
            this.apierror = result.error;
        }
      },

      installationsLoaded(status, result) {
        if (status) {
          var maxid = 0;
          this.installations = result.result;
          for (var inst of result.result) {
            var instN = Number(inst.installationId);
            if (instN > maxid) {
              maxid = instN;
            }
          }
          this.newid = maxid + 1;
        } else {
            this.apierror = result.error;
        }
      },

      displayInstHelp() {
        var selected = this.installations.filter( (m) => Number(m.installationId) == this.newid);
        if (selected.length == 0) {
            return "";
        } else {
            return selected[0].customer + ", " + selected[0].city + ", " + selected[0].country;
        }
      },
    },

    mounted() {
      API.transact("installations", this.installationsLoaded);
    }
}
</script>

<style scoped>

div.page {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
}

div.row {
    margin: auto;
    padding-top: 10px;
    height: 30px;
}

div.action {
  text-align: center;
  color: #2c3e50;
  background-color: azure;
  border: 1px solid black;
  border-radius: 4px;
  margin-right: 2px;
  padding: 4px;
  display: inline-block;
  height: 20px;
  width: 100px;
  cursor:pointer;
}
</style>
