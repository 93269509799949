// declare global { getApiUrl() }

export class API {


    static login(user, password, callback) {
        fetch(window.getApiUrl() + "auth/login", {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({ "username": user, "password": password })
        })
            .then(response => response.json())
            .then(result => {
                if (result.token) {
                    localStorage.setItem("token", result.token)
                    localStorage.setItem("isAdmin", result.isAdmin)
                    callback(true, result);
                }
                else {
                    callback(false, result);
                }
            })
            .catch(error => {
                callback(false, error);
            })
            ;
    }

    static transact(url, callback, data, method) {
        var throw_was_in_callback = false;
        var params = {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        }
        if (data) {
            params.headers['Content-Type'] = 'application/json';
            params.method = 'POST';
            if (method) {
                params.method = method;
            }
            params.body = JSON.stringify(data);
        }

        var apiBase = window.getApiUrl();
        if (apiBase.endsWith("/")) {
            apiBase = apiBase.substr(apiBase, apiBase.length - 1);
        }
        if (url.startsWith("/")) {
            url = url.substr(1);
        }
        fetch(apiBase + "/" + url, params)
            .then(response => {
                if (response.status === 403) {
                    localStorage.clear()
                    window.location.replace("/login");
                }
                return response.json()
            })
            .then(result => {
                throw_was_in_callback = true;
                if (result.status == 0) {
                    callback(true, result);
                } else {
                    callback(false, result);
                }
            })
            .catch(error => {
                console.log("err: " + error)
                if (!throw_was_in_callback) {
                    callback(false, error);
                }

            })
    }

    /**
     * 
     * @param {*} url the url to call
     * @param {*} callback a callback method to inform about progress and success/fail
     * @param {*} file the file (a File object) to upload
     * 
     * It seems like fetch() cannot handle file uploads the way we want so we use XMLHttpRequest.
     */
    static upload(url, callback, file) {
        var apiBase = window.getApiUrl();
        if (apiBase.endsWith("/")) {
            apiBase = apiBase.substr(apiBase, apiBase.length - 1);
        }

        if (url.startsWith("/")) {
            url = url.substr(1);
        }
        API.uploadInternal(apiBase, url, callback, file);
    }

    static uploadHackForRotec(url, callback, file) {
        const apiBase = window.location.protocol + "//" + window.location.host + "/rotec/v1";
        this.uploadInternal(apiBase, url, callback, file);
    }

    static uploadInternal(apiBase, url, callback, file) {
        const ajax = new XMLHttpRequest;
        ajax.upload.addEventListener("progress", (event) => callback("progress", event), false);
        ajax.addEventListener("load", (event) => callback("load", event), false);
        ajax.addEventListener("error", (event) => callback("error", event), false);
        ajax.addEventListener("abort", (event) => callback("abort", event), false);
        ajax.open("POST", apiBase + "/" + url + "?filename=" + file.name);
        ajax.setRequestHeader("Content-Type", "application/octet-stream");
        ajax.send(file);
    }

    static setApikey(newkey) {
        API.apikey = newkey;
    }
}
