<template>
    <div class="page">
        <div>Change User: {{ user.username }}</div>
        <form>
            <table>
                <tr>
                    <td><label class="label" for="username">Username: </label></td>
                    <td><input class="input" type="text" id="username" v-model="user.username"></td>
                </tr>
                <tr>
                    <td><label class="label" for="email">E-Mail: </label></td>
                    <td><input class="input" type="text" id="email" v-model="user.email"></td>
                </tr>
                <tr>
                    <td><label class="label" for="name">Name: </label></td>
                    <td><input class="input" type="text" id="name" v-model="user.name"></td>
                </tr>
                <tr>
                    <td><label class="label" for="name">Rolle: </label></td>
                    <select v-model="user.roles">
                        <option v-for="option in roles" :value="[option]" :key="option.id">
                        {{ option.name }}
                    </option>
            </select>
                </tr>
                <tr>
                    <td><label class="label" for="allowedInstallations">allowed installations: </label></td>
                    <VueMultiselect v-model="user.allowedInstallations"  :multiple="true" :options="installationIDs" :custom-label="customLabel" >
                    </VueMultiselect>
                </tr>
            </table>

            <div class="row action" @click="editUser()">Submit</div>
            <div class="row">
                {{this.apierror}}
            </div>

        </form>
    </div>
</template>
<script>

    import { API } from '../API.js'
    import VueMultiselect from 'vue-multiselect'

    export default {
        name: 'User',
        props: {
        },
        components: { VueMultiselect},
        data() {
            return {
                user: Object,
                apierror: "",
                roles: Object,
                installations: [],
                installationIDs: [1, 2, 3, 4, 5],
            }
        },
        methods: {
            editUser() {
                // logic here
                this.submitChanges();
            },
            UserLoaded(status, object) {
                this.user = object.result;
                if (!status) {
                    alert("Unable to load user for some reason?");
                }
            },
            RolesLoaded(status, object) {
                this.roles = object.result;
                if (!status) {
                    alert("Unable to load user for some reason?");
                }
            },
            InstallationsLoaded(status, object) {
                this.installationIDs = object.result.map(item => item.installationId)
                this.installations = object.result
                if (!status) {
                    alert("Unable to load user for some reason?");
                }
            },
            submitChanges() {
                API.transact("/user/" + this.$route.params.username, this.submitted, this.user, "PUT");
            },
            submitted(status) {
                if (status) {
                    alert("user Updated!")
                } else {
                    alert(`unable to update`)
                }
            },
            customLabel(installationID) {
                return `${installationID} - ${this.installations.find((i) => {return i.installationId === installationID})?.instname} `
            }

        },
        mounted() {
            API.transact("/user/" + this.$route.params.username, this.UserLoaded);
            API.transact("/role/", this.RolesLoaded);
            API.transact("/installations/", this.InstallationsLoaded);
        },
}

</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
th,td {
    text-align: left;
    font-size: 15px;
    color:black;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


div.page {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
}

div.row {
    margin: auto;
    padding-top: 10px;
    height: 30px;
}

.label { width: 100px; }

.input { width: 300px; }

div.action {
    text-align: center;
    color: #2c3e50;
    background-color: azure;
    border: 1px solid black;
    border-radius: 4px;
    margin-right: 2px;
    padding: 4px;
    display: inline-block;
    height: 20px;
    width: 100px;
    cursor:pointer;
}

::v-deep span.multiselect__tag {
background: #b3b6b6 !important;
color: #000000 !important;
}

::v-deep .multiselect__option--highlight {
    background: #b3b6b6 !important;
}

</style>
