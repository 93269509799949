<template>
    <div class="filter">
      <div class="input">
      Filter: <input v-model="instfilter" v-on:change="handlefilterchange" class="searchbox">
      </div>
      <div class="input">
      <input type="checkbox" v-model="connectfilter" v-on:change="handleconnectchange"> Connected only
      </div>
    </div>
    <table class="installations">
    <Installation header="yes" />
    <Installation 
      v-for="i in matchfilter(installations)"
      @expand="expand"
      header="no" 
      :key="i.installationId" 
      :installation=i
      :expanded = expandedInstallation
    />
    </table>
</template>

<script>
import { API } from '../API.js'
import Installation from './Installation.vue'

export default {
  name: 'InstallationPage',
  data() {
    return {
      instfilter: "",
      connectfilter: false,
      installations: [],
      expandedInstallation: "none",
      reloadtimer : null
    }
  },
  components: {
    Installation
  },
  props: {
  },
  methods: {
    matchfilter(installations) {
      return installations.filter(installation => (
        ( this.connectfilter == false || installation.tunnelStatus.connected == true )
        &&
        (
          this.instfilter === null
          || this.instfilter === ""
          || installation.installationId.toLowerCase().indexOf(this.instfilter.toLowerCase()) !== -1
          || installation.country.toLowerCase().indexOf(this.instfilter.toLowerCase()) !== -1
          || installation.city.toLowerCase().indexOf(this.instfilter.toLowerCase()) !== -1
          || installation.instname.toLowerCase().indexOf(this.instfilter.toLowerCase()) !== -1
        )
      ));
    },
    installationsLoaded(status, object) {
      if (!status) {
        alert("Unable to load installations for some reason?");
      } else {
        this.installations = object.result;
        this.reloadtimer = setTimeout(() => { API.transact("/installations", this.installationsLoaded)}, 10000);
      }
    },
    expand(instID) {
      this.expandedInstallation = instID
    }
  },
  mounted() {
    API.transact("/installations", this.installationsLoaded);
  },
  unmounted() {
    if (this.reloadtimer) {
      clearTimeout(this.reloadtimer);
      this.reloadtimer = null;
    }
  }
}
</script>

<style scoped>
.filter {
        margin-left: auto;
        margin-right: auto;
        width: 600px;
}

/* input element, may be filter input or "connected" checkbox */

.input {
        width: 250px;
        display: inline-block;
        margin-left: 10px;
        margin-right: 10px;
}

.searchbox {
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: center;
}
.installations {
        margin-left: auto;
        margin-right: auto;
        table-layout: fixed;
        width: 860px;
}
</style>
