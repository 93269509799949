<template>
    <div class="page">
        <div class="row">
            Username:  <input placeholder="username" v-model="username" v-on:input="idchange"/>
            Password:  <input placeholder="password" type="password" v-model="password" v-on:input="idchange"/>
        </div>
        <div class="row action" @click="login()">Login</div>
        <div class="row">
            {{this.apierror}}
        </div>
    </div>
</template>


<script>
    import { API } from '../API.js'

    export default {
        name: "Login",
        props: {
        },
        data() {
            return {
                username: "",
                password: "",
                apierror: "",
            }
        },
        methods: {
            idchange() {
            },
            login() {
                API.login(this.username, this.password, this.createCallback);
            },

            createCallback(status, result) {
                if (status) {
                    this.apierror = "";
                    this.$router.push({path:"installations"})
                } else {
                    this.apierror = result.message;
                }
            },

            mounted() {
                API.transact("login", this.login);
            },
        },
    }
</script>

<style scoped>

div.page {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
}

div.row {
    margin: auto;
    padding-top: 10px;
    height: 30px;
}

div.action {
    text-align: center;
    color: #2c3e50;
    background-color: azure;
    border: 1px solid black;
    border-radius: 4px;
    margin-right: 2px;
    padding: 4px;
    display: inline-block;
    height: 20px;
    width: 100px;
    cursor:pointer;
}
</style>
