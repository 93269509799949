<template>
  <div class="menu">
    <div class="menuitem_1">Installations</div>
    <ul>
      <li><div class="menuitem_2"><router-link to="/installations">Installations</router-link></div></li>
      <li v-if:="isAdmin"><div class="menuitem_2"><router-link to="/installations/create">New Installation</router-link></div></li>
      <li v-if:="isAdmin"><div class="menuitem_2"><router-link to="/installations/newhardware">Configure new TruCom</router-link></div></li>
    </ul>
    <div class="menuitem_1">Operation</div>
    <ul>
      <li><div class="menuitem_2"><router-link to="/schedules">Schedules</router-link></div></li>
      <li><div class="menuitem_2"><router-link to="/certifications">Certifications</router-link></div></li>
      <li><div class="menuitem_2"><router-link to="/measurements">Measurements</router-link></div></li>
    </ul>
    <div class="menuitem_1">Upload</div>
    <ul>
      <li><div class="menuitem_2"><router-link to="/upload/measurement">Measurement</router-link></div></li>
      <li><div class="menuitem_2"><router-link to="/upload/reference">Reference</router-link></div></li>
      <li><div class="menuitem_2"><router-link to="/upload/h5file">H5 File</router-link></div></li>
    </ul>
        <div v-if:="isAdmin" class="menuitem_1">User</div>
        <ul v-if:="isAdmin" >
            <li><div class="menuitem_2"><router-link to="/user">User</router-link></div></li>
            <li><div class="menuitem_2"><router-link to="/user/create">New User</router-link></div></li>
        </ul>
        <div class="menuitem_1"><router-link to="/logout">Logout</router-link></div>
  </div>
</template>

<script>
export default {
    name: 'Menu',
    data() {
        return {
            isAdmin: JSON.parse(localStorage.getItem("isAdmin")) || false
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.menu {
    color: black;
    font-family: Helvetica,Arial,sans-serif;
    font-size: 12px;
    margin: 10px;
}
.menuitem_1 {
    color: #ffffff;
    font-size: 18px;
    text-decoration: none;
    margin-left: 10px;
}
.menuitem_2 {
    color: #ffffff;
    font-size: 15px;
    text-decoration: none;
}
ul {
    white-space: nowrap;
    margin-right: 10;
    margin-left: -15;
}
li {
    font-weight: bold;
    color: #ffffff;
    font-size: 15px;
    text-decoration: none;
}
a {
    color: #ffffff;
}
</style>
