<template>
    <div class="page">
        <div class="row">
        Configure device 0000 to new site:  <input maxlength=4 v-model="newid"/>
        </div>
        <div class="row">
        {{displayInstHelp()}}
        </div>
        <div class="row action" @click="submitcreate()">Configure</div>
        <div class="row">
        {{this.apierror}}
        </div>
    </div>
</template>

<script>
import { API } from '../API.js'

export default {
    name: "HardwareConfigure",
    props: {
    },
    data() {
      return {
        newid: "????",
        apierror: "Warning: this will make a previous hardware with the same site ID unusable!",
        installations: [],
        buttonlocked : false,
      }
    },
    methods: {
      submitcreate() {
        if (this.buttonlocked) {
            return;
        }
        var selected = this.installations.filter( (m) => Number(m.installationId) == this.newid);
        if (selected.length == 0) {
          this.apierror = "Installation " + this.newid + " is not configured!"
          return;
        }
        API.transact("installations/"+this.newid+"/newhardware", this.createCallback);
      },

      createCallback(status, result) {
        if (status) {
            this.apierror = "Configure started, expect result email";
            this.buttonlocked = true;
        } else {
            this.apierror = result.error;
        }
      },

      installationsLoaded(status, result) {
        if (status) {
          var maxid = 0;
          this.installations = result.result;
          for (var inst of result.result) {
            var instN = Number(inst.installationId);
            if (instN > maxid) {
              maxid = instN;
            }
          }
          this.newid = maxid;
        } else {
            this.apierror = result.error;
        }
      },

      displayInstHelp() {
        var selected = this.installations.filter( (m) => Number(m.installationId) == this.newid);
        if (selected.length == 0) {
            return "Not possible, site id does not exist";
        } else {
            return selected[0].customer + ", " + selected[0].city + ", " + selected[0].country + " -- " + selected[0].instname;
        }
      },
    },

    mounted() {
      API.transact("installations", this.installationsLoaded);
    }
}
</script>

<style scoped>

div.page {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
}

div.row {
    margin: auto;
    padding-top: 10px;
    height: 30px;
}

div.action {
  text-align: center;
  color: #2c3e50;
  background-color: azure;
  border: 1px solid black;
  border-radius: 4px;
  margin-right: 2px;
  padding: 4px;
  display: inline-block;
  height: 20px;
  width: 100px;
  cursor:pointer;
}
</style>
