<template>
    <div class="filter">
      <div class="filter-row">
      <div class="filter-label">Filter:</div>
      <div class="filter-reset" title="Reset filter" v-on:click="resetinstfilter"></div>
      <input v-model="instfilter" v-on:change="handlefilterchange" class="searchbox">
      </div>
      <div class="filter-row">
        <div class="filter-label">Date range:</div>
        <div class="filter-reset" title="No filter" v-on:click="resetdatefilter"></div>
        <v-date-picker v-model="range.start" :masks="masks">
              <template class="filter-date" v-slot="{ inputValue, inputEvents }">
                <input
                  :value="inputValue"
                  v-on="inputEvents"
                  v-on:change="handledatechange"
                  class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                />
              </template>
        </v-date-picker>
              to
        <v-date-picker v-model="range.end" :masks="masks">
              <template class="filter-date" v-slot="{ inputValue, inputEvents }">
                <input
                  :value="inputValue"
                  v-on="inputEvents"
                  class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                />
              </template>
        </v-date-picker>
      </div>
      <div class="filter-row">
        <div class="filter-label">Queues:</div>
        <div class="filter-reset" title="all queues" v-on:click="resetqueuefilter"></div>
        <div class="checkbox-container" v-for="queue of measurements.queues" :key=queue>
          <label>
            <input v-model="queuecheck[queue]" type=checkbox :name=queue>
            {{queue}}
          </label>
        </div>
      </div>
    </div>
    <table class="measurements">
    <Measurement header="yes"/>

    <Measurement v-for="i in matchfilter(measurements.measurements)"
      header="no"
      :key=i.uniqueID+i.queue
      :measurement=i 
      :installation=measurements.installations[i.installationId]
      :queues=measurements.queues
    />

    </table>
</template>

<script>
import { API } from '../API.js';
import Measurement from './Measurement.vue';

export default {
  name: 'MeasurementPage',
  data() {
    return {
      instfilter: "",
      connectfilter: false,
      measurements: {
        installations: {},
        measurements: [],
        queues: []
      },
      range: {
        start: new Date(Date.now() - 1000 * 60 * 60 * 24 * 7),
        end: new Date(),
      },
      masks: {
        input: 'YYYY-MM-DD',
      },
      startDateString: '',
      endDateString: '',
      queuecheck: {},
    }
  },
  components: {
    Measurement
  },
  props: {
  },
  methods: {
    matchfilter(measurements) {

      // The datepicker applet always adds the current time so we need to reset the time to include all day.
      if (this.range.start == null) {
        this.startDateString = "";
      } else {
        this.startDateString = this.range.start.toISOString().substring(0, 10) + " 00:00:00";
      }
      if (this.range.end == null) {
        this.endDateString = "9999-99-99";
      } else {
        this.endDateString   = this.range.end.toISOString().substring(0, 10) + " 23:59:59";
      }

      return measurements
      .filter(measurement => this.match(measurement))
      .sort(this.compareMeasurements);
    },
    match(measurement) {
      if (measurement.isoDateUTC > this.endDateString
      ||  measurement.isoDateUTC < this.startDateString) {
        return false;
      }

      if (!this.queuecheck[measurement.queue]) {
        return false;
      }

      var installation = this.measurements.installations[measurement.installationId];
      return (
        this.instfilter === null
        || this.instfilter === ""
        || measurement.installationId.toLowerCase().indexOf(this.instfilter.toLowerCase()) !== -1
        || installation.country.toLowerCase().indexOf(this.instfilter.toLowerCase()) !== -1
        || installation.city.toLowerCase().indexOf(this.instfilter.toLowerCase()) !== -1
        || installation.instname.toLowerCase().indexOf(this.instfilter.toLowerCase()) !== -1
      );
    },
    compareMeasurements(a, b) {
      var dateComp = (a.isoDateUTC === b.isoDateUTC ? 0 : a.isoDateUTC > b.isoDateUTC ? -1 : 1);
      if (dateComp != 0) return dateComp;
      var instComp = a.installationId.localeCompare(b.installationId);
      if (instComp != 0) return instComp;
    },
    measurementsLoaded(status, object) {
      if (!status) {
        alert("Unable to load measurements for some reason? status = "+status);
      } else {
        this.measurements = object.result;
        for (var queue of this.measurements.queues) {
          this.queuecheck[queue] = true;
        }
      }
    },
    
    handledatechange() {
        API.transact("/measurements?minimumTime=" + this.range.start.getTime(), this.measurementsLoaded);
    },

    // reset input elements 
    resetinstfilter() {
      console.log("resetinstfilter");
      this.instfilter = "";
    },
    resetdatefilter() {
      this.range.start = new Date('2010-01-01 00:00:00');
      this.range.end   = new Date();
      API.transact("/measurements?minimumTime=" + this.range.start.getTime(), this.measurementsLoaded);
    },
    resetqueuefilter() {
      for (var queue in this.queuecheck) {
        this.queuecheck[queue] = true;
      }
    }

  },
  mounted() {
    API.transact("/measurements", this.measurementsLoaded);
  },
}
</script>

<style scoped>
.filter {
  margin-left: auto;
  margin-right: auto;
  width: 700px;
}

.filter-row {
  height: 45px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}

.filter-row div {
  display: inline-block;
}

.filter-label {
  display: inline-block;
  width: 100px;
}

.filter-reset {
  background: url('../assets/clearfilter.png');
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.searchbox {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.measurements {
  margin-left: auto;
  margin-right: auto;
  table-layout: fixed;
  width: 860px;
}

.checkbox-container {
  background-color: azure;
  display: inline-block;
  min-width: 80px;
  border: 1px solid black;
  border-radius: 4px;
  margin-right: 2px;
}


</style>
