<template>
    <div class="filter">
      <div class="filter-row">
        <div class="filter-label">Filter:</div>
        <div class="filter-reset" title="Reset filter" v-on:click="resetinstfilter"></div>
        <input v-model="instfilter" v-on:change="handlefilterchange" class="searchbox">
      </div>
    </div>
    <table class="certifications">
        <Certification header="yes" @sort="setSortCriterion" :sortorder=sortCriterion />
        <Certification v-for="i in sortByNext(certifications)"
            header="no"
            :key="i.id + ':' + i.type"
            :certification=i
            :installation=installations[i.id]
        />
    </table>
</template>

<script>
import {API} from '../API.js';
import Certification from './Certification.vue';

export default {
    name: 'CertificationPage',
    data() {
        return {
            instfilter: "",
            certifications: [],
            installations: {},
            sortCriterion: "date"
        }
    },
    components: {
        Certification
    },
    props: {

    },
    methods: {
        certificationsLoaded(status, object) {
            this.certifications = object.result;
        },
        installationsLoaded(status, object) {
            this.installations = {};
            for (var installation of object.result) {
                var id = installation.installationId;
                this.installations[id] = installation;
            }
        },
        setSortCriterion(crit) {
            this.sortCriterion = crit;
        },
        sortByNext(certifications) {
            return certifications
            .filter((x) => {
                if (x.date == "") { return false; }
                var installation = this.installations[x.id];
                if (installation == "") { return false; }
                return this.instfilter === null
                    || this.instfilter === ""
                    || installation.installationId.toLowerCase().indexOf(this.instfilter.toLowerCase()) !== -1
                    || installation.country.toLowerCase().indexOf(this.instfilter.toLowerCase()) !== -1
                    || installation.city.toLowerCase().indexOf(this.instfilter.toLowerCase()) !== -1
                    || installation.instname.toLowerCase().indexOf(this.instfilter.toLowerCase()) !== -1
            })
            .sort( (a, b) => {
                var comp;
                var crit = this.sortCriterion;
                if (crit == 'type') {
                    if (a.type == b.type) {
                        crit = 'date';
                    }
                    // these two should not happen but leave them in in case we ever remove the filter
                    else if (a.date == "")                return 1;
                    else if (b.date == "")                return -1;
                    else return (a.type.localeCompare(b.type));
                }

                if (crit == 'date') {
                    if (a.date == b.date) {
                        return a.id.localeCompare(b.id);
                    }
                    // these two should not happen but leave them in in case we ever remove the filter
                    if (a.date == "")                return 1;
                    if (b.date == "")                return -1;
                    return (a.date.localeCompare(b.date));
                }
                if (crit == 'country') {
                    comp = this.installations[a.id].country.localeCompare(this.installations[b.id].country);
                }
                if (crit == 'city') {
                    comp = this.installations[a.id].city.localeCompare(this.installations[b.id].city);
                }
                if (crit == 'instname') {
                    comp = this.installations[a.id].instname.localeCompare(this.installations[b.id].instname);
                }
                if (comp != 0) { return comp; }
                // everything else, and identical keys, sort by installation id
                return (a.id.localeCompare(b.id));
            });
        }
    },
    mounted() {
        API.transact("/certifications", this.certificationsLoaded);
        API.transact("/installations", this.installationsLoaded);
    }
}
</script>

<style scoped>
.filter {
        margin-left: auto;
        margin-right: auto;
        width: 400px;
}

.filter-row {
  width: 400px;
  height: 45px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}

.filter-row div {
  display: inline-block;
}

.filter-label {
  display: inline-block;
  width: 100px;
}

.filter-reset {
  background: url('../assets/clearfilter.png');
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.searchbox {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

</style>