<template>
    <tr class="certification" v-on:click="gotoInstEditDevice(certification)">
        <td v-if="header == 'no'" class="action installationid">{{certification.id}}</td>
        <td v-if="header == 'no'" class="action country">{{installation ? installation.country : "??"}}</td>
        <td v-if="header == 'no'" class="action city">{{installation ? installation.city : "??"}}</td>
        <td v-if="header == 'no'" class="action name">{{installation ? installation.instname : "??"}}</td>
        <td v-if="header == 'no'" class="action date">{{certification.date}}</td>
        <td v-if="header == 'no'" class="action type">{{certification.type}}</td>

        <th v-if="header == 'yes'" :class="issortedby('installationid')" @click.stop="sort('installationid')"   class="installationid">Installation</th>
        <th v-if="header == 'yes'" :class="issortedby('country')"        @click.stop="sort('country')"          class="country">Country</th>
        <th v-if="header == 'yes'" :class="issortedby('city')"           @click.stop="sort('city')"             class="city">City</th>
        <th v-if="header == 'yes'" :class="issortedby('instname')"       @click.stop="sort('instname')"         class="name">Installation</th>
        <th v-if="header == 'yes'" :class="issortedby('date')"           @click.stop="sort('date')"             class="date">Date</th>
        <th v-if="header == 'yes'" :class="issortedby('type')"           @click.stop="sort('type')"             class="type">Type</th>
    </tr>
</template>

<script>
export default {
    name: 'Certification',
    props: {
        certification: Object,
        installation: Object,
        header: String,
        sortorder: String
    },
    methods: {
        issortedby(order) {
            return "action " + (this.sortorder == order ? 'highlight' : '');
        },
        gotoInstEditDevice(schedule) {
            if (schedule && schedule.id) {
                this.$router.push("/installations/"+schedule.id+"/Device");
            }
        },
        sort(criterion) {
            this.$emit('sort', criterion);
        }
    }
}
</script>

<style scoped>

tr.certification {
    height: 30px;
}

td {
  text-align: left;
  font-size: 15px;
  color:black;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

th {
    text-align: center;
    color: #2c3e50;
    background-color: azure;
    border: 1px solid black;
    border-radius: 4px;
    margin-right: 2px;
    padding: 4px;
}

.action {
    cursor:pointer;
}

th.highlight {
    color: white;
    background-color: #0f2050;
}

.installationid { width: 100px; }
.country { width: 100px; }
.city { width: 150px; max-width: 150px;}
.name { width: 200px; }
.date { width: 160px; }
.type { width: 160px; }
</style>