<template>
    <tr class="user" @click.stop="startedit(user.username)">
        <td v-if="header == 'no'" class="id">{{user.id}}</td>
        <td v-if="header == 'no'" class="username">{{user.username}}</td>
        <td v-if="header == 'no'" class="name">{{user.name}}</td>
        <td v-if="header == 'no'" class="email">{{user.email}}</td>
        <td v-if="header == 'no'" class="action" @click.stop="changepw(user.username)">PW reset</td>
        <td v-if="header == 'no'" class="action delete" @click.stop="$emit('deleteUser', user)">Delete</td>

        <!-- <td v-if="header == 'no'" class="roles">{{user.roles}}</td> -->

        <th v-if="header == 'yes'" class="id">ID</th>
        <th v-if="header == 'yes'" class="username">Username</th>
        <th v-if="header == 'yes'" class="name">Name</th>
        <th v-if="header == 'yes'" class="email">E-Mail</th>
        <th v-if="header == 'yes'" class="passwordreset"></th>
        <th v-if="header == 'yes'" class="deleteheader"></th>

        <!-- <th v-if="header == 'yes'" class="roles">Roles</th> -->
    </tr>
</template>
<script>
    export default {
        name: 'User',
        props: {
            user: Object,
            header: String,
        },
        data() {
            return {
            }
        },
        methods: {
            startedit(username) {
                this.$router.push("/user/" + username);
                return true;
            },
            changepw(username) {
                this.$router.push("/user/" + username + "/resetpw");
                return true;
            },
        },
        mounted() {
            if (this.user == null) {
                // we are in the header ...
                    return;
            }
        },
    }
</script>

<style scoped>
th,td {
    text-align: left;
    font-size: 15px;
    color:black;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

tr.user {
    height: 30px;
}

.action {
    text-align: center;
    color: #2c3e50;
    background-color: azure;
    border: 1px solid black;
    border-radius: 4px;
    margin-right: 2px;
    padding: 4px;
    display: inline-block;
    height: 20px;
    width: 100px;
    cursor:pointer;
}

.delete {
    background-color: red;
}

input { width: 120px; }

.id { width: 30px; }

.username { width: 100px; }
.email { width: 300px; }
.name { width: 150px}

</style>
