<template>
    <tr class="schedule" v-on:click="gotoInstEditContract(schedule)">
        <td v-if="header == 'no'" class="action installationid">{{schedule.id}}</td>
        <td v-if="header == 'no'" class="action country">{{installation ? installation.country : "??"}}</td>
        <td v-if="header == 'no'" class="action name">{{installation ? installation.instname : "??"}}</td>
        <td v-if="header == 'no'" class="action interval">{{schedule.schedule}}-{{schedule.dayno}}</td>
        <td v-if="header == 'no'" class="action date">{{schedule.nextMeasurement}}</td>
        <td v-if="header == 'no'" class="action date">{{schedule.end}}</td>
        <td v-if="header == 'no'" class="action remaining">{{schedule.remaining}}</td>

        <th v-if="header == 'yes'" :class="issortedby('installationid')" @click.stop="sort('installationid')"   class="installationid">Installation</th>
        <th v-if="header == 'yes'" :class="issortedby('country')"        @click.stop="sort('country')"          class="country">Country</th>
        <th v-if="header == 'yes'" :class="issortedby('instname')"       @click.stop="sort('instname')"         class="name">Installation</th>
        <th v-if="header == 'yes'"                                                                              class="interval">Interval</th>
        <th v-if="header == 'yes'" :class="issortedby('next')"           @click.stop="sort('next')"             class="date">Next</th>
    <th v-if="header == 'yes'"                                                                                  class="date">Contract End</th>
        <th v-if="header == 'yes'" :class="issortedby('remaining')"      @click.stop="sort('remaining')"        class="remaining">Remaining</th>
    </tr>
</template>

<script>
export default {
    name: 'Schedule',
    props: {
        schedule: Object,
        installation: Object,
        header: String,
        sortorder: String
    },
    methods: {
        issortedby(order) {
            return "action " + (this.sortorder == order ? 'highlight' : '');
        },
        gotoInstEditContract(schedule) {
            if (schedule && schedule.id) {
                this.$router.push("/installations/"+schedule.id+"/Contract");
            }
        },
        sort(criterion) {
            this.$emit('sort', criterion);
        }
    }
}
</script>

<style scoped>

tr.schedule {
    height: 30px;
}

td {
  text-align: left;
  font-size: 15px;
  color:black;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

th {
    text-align: center;
    color: #2c3e50;
    background-color: azure;
    border: 1px solid black;
    border-radius: 4px;
    margin-right: 2px;
    padding: 4px;
}

.action {
    cursor:pointer;
}

th.highlight {
    color: white;
    background-color: #0f2050;
}

.installationid { width: 100px; }
.country { width: 100px; }
.name { width: 200px; }
.interval { width: 100px; }
.date { width: 160px; }
</style>