<template>

    <div class="siteinfo">
        <div class="id">{{siteData.installationId}}</div> -
        <div class="country">{{siteData.country}}</div> - 
        <div class="city">{{siteData.city}}</div> - 
        <div class="customer">{{siteData.customer}}</div> - 
        <div class="instname">{{siteData.instname}}</div>
    </div>

    <div class="pagemenu">
        <div v-for="page of pages" :key="page" class="pagemenu-item" :class="menubackground(page)" v-on:click="selectpage(page)"
        >
            {{page}}
        </div>
    </div>

    <table class="table">
        <tr>
          <fieldset>
            <td class="siteinfo">
            </td>
            <td class="siteinfo">
                <div class="minus"><button v-on:click="modifySiteID(-1)">-</button></div>
                <div class="id"><input v-model="siteData.installationId" v-on:change="selectsite(siteData.installationId)"></div>
                <div class="plus"><button v-on:click="modifySiteID(1)">+</button></div>
            </td>
            <td class="siteinfo">
                <div class="minus"><button v-on:click="modifyCompID(-1)">-</button></div>
                <div class="id"><input v-model="compData.installationId" v-on:change="selectcompare(compData.installationId)"></div>
                <div class="plus"><button v-on:click="modifyCompID(1)">+</button></div>
            </td>
            </fieldset>
          </tr>
          <fieldset :disabled="!isAdmin">
          <tr class="row" v-for="fielddef of this.fielddefs" :key=fielddef.xmlPath>
            <td v-if="fielddef.inputType == 'separator'" colspan=2><hr></td>
            <td v-if="fielddef.inputType != 'separator'" class="label">{{ fielddef.friendlyName }}</td>
            <td v-if="fielddef.inputType != 'separator'" class="field">
                <div v-if="fielddef.inputType == null || fielddef.inputType == '' || fielddef.inputType == 'text'">
                    <input class="input" type="text" v-model="this.fieldvalue[fielddef.xmlPath]">
                </div>
                <div v-else-if="fielddef.inputType == 'date'">
                    <v-date-picker v-model="this.fieldvalue[fielddef.xmlPath]" :masks="masks">
                        <template class="filter-date" v-slot="{ inputValue, inputEvents }">
                            <input
                            :value="inputValue"
                            v-on="inputEvents"
                            class="input"
                            />
                        </template>
                    </v-date-picker>
                </div>
                <div v-else-if="fielddef.inputType == 'select'">
                    <select class="input" v-model="this.fieldvalue[fielddef.xmlPath]">
                    <option v-for="option of fielddef.selectOptions" :key="option">{{option}}</option>
                    </select>
                </div>
                <div v-else-if="fielddef.inputType == 'checkbox'">
                    <input type="checkbox" v-model="this.fieldvalue[fielddef.xmlPath]"> {{ this.fieldvalue[fielddef.xmlPath] }}
                </div>
                <div v-else-if="fielddef.inputType == 'display'">
                    <input class="input readonly" readonly type="text" v-model="this.fieldvalue[fielddef.xmlPath]">
                </div>
                <div v-else-if="fielddef.inputType == 'textarea'">
                    <textarea class="input" v-model="this.fieldvalue[fielddef.xmlPath]">
                    </textarea>
                </div>
                <div v-else-if="fielddef.inputType == 'savebutton'">
                    <button class="pagemenu-item" v-bind:disabled="this.savebuttontimeout !== null" v-on:click="saveChangedValues()">{{ this.savebuttontext }}</button>
                </div>
                <div v-else>
                    {{fielddef.inputType}} not implemented, value is {{ fielddef.currentValue }}
                </div>
            </td>
            <td class="field">
                {{this.compvalue[fielddef.xmlPath]}}
            </td>
          </tr>
        </fieldset>
        <tr v-if:="isAdmin" class="row">
          <td class="label"></td>
          <td class="field"><button class="pagemenu-item" v-bind:disabled="this.savebuttontimeout !== null" v-on:click="saveChangedValues()">{{ this.savebuttontext }}</button></td>
        </tr>
    </table>
</template>

<script>
import { clearTimeout } from 'timers';
import { API } from '../API.js'

export default {
  name: 'InstallationPage',
  data() {
    return {
      siteData:  {},        // installation data to show on top row to edit
      compData:  {},        // installation data to show above "compare to" column
      fielddefs: [],        // field parameters
      fieldvalue: {},       // field values to edit
      origFieldValue: {},
      compvalue:  {},       // field values to compare to, read only
      masks: {
        input: 'YYYY-MM-DD',
      },
      pages:    // TODO move these to API
      [
        "Customer",
        "Device", 
        "Contract",
        // "Network",       Network is unused ever since we did the Webservice.
        "On Site Setup",
        "Analysis Setup",
        "Rope Data",
        "Splice Info",
      ],
      currentPage: "Customer",
      savebuttontext: "Save changes",
      savebuttontimeout: null,
      isAdmin: JSON.parse(localStorage.getItem("isAdmin")) || false,
    }
  },
  methods: {
    fieldsLoaded(status, object) {
      this.fieldvalue = {};
      this.origFieldValue = {};
      if (!status) {
        console.log("Unable to load form defs for some reason?");
      } else {
        this.fielddefs = object.result.parameters;
        this.siteData  = object.result.instData;
        this.fieldvalue = {};
        this.origFieldValue = {};
        for (var field of this.fielddefs) {
            if (field.inputType == "checkbox") {
                this.fieldvalue[field.xmlPath] = (field.currentValue > 0);
            } else {
                this.fieldvalue[field.xmlPath] = field.currentValue;
            }
            this.origFieldValue[field.xmlPath] = this.fieldvalue[field.xmlPath];
        }
      }
    },
    compareLoaded(status, object) {
      this.compvalue = {};
      if (!status) {
        console.log("Unable to load compare defs for some reason?");
      } else {
        this.compData = object.result.instData;
        for (var field of object.result.parameters) {
            if (field.inputType == "checkbox") {
                this.compvalue[field.xmlPath] = (field.currentValue > 0);
            } else {
                this.compvalue[field.xmlPath] = field.currentValue;
            }
        }
      }
      console.log("compData.installationId is now "+this.compData.installationId);
    },
    selectpage(page) {
      var id = this.$route.params.id;
      var compID = this.$route.query.compare;
      this.$router.push({ path: "/installations/"+id+"/"+page, query: {'compare': compID}});
      this.currentPage = page;
      API.transact("/installations/"+id+"/"+page, this.fieldsLoaded);
      if (this.$route.query.compare) {
          API.transact("/installations/"+this.$route.query.compare+"/"+page, this.compareLoaded);
      }
    },

    modifyCompID(value) {
        var curVal = Number(this.$route.query.compare);
        var newVal = curVal > 0 ? curVal + value : value;
        if (newVal < 0) {
            newVal = 0;
        }
        this.selectcompare(newVal);
    },
    selectcompare(compID) {
      compID = (""+compID).padStart(4, "0");
      if (this.$route.query.compare !== compID) {
          var id = this.$route.params.id;
          var page = this.$route.params.page;
          this.$router.push({ path: "/installations/"+id+"/"+page, query: {'compare': compID}});
          this.compData.installationId = compID;    // needed in case of failed API load
          API.transact("/installations/"+compID+"/"+page, this.compareLoaded);
      }
    },

    modifySiteID(value) {
        var curVal = Number(this.$route.params.id);
        var newVal = curVal > 0 ? curVal + value : value;
        if (newVal < 0) {
            newVal = 0;
        }
        this.selectsite(newVal);
    },
    selectsite(siteID) {
      siteID = (""+siteID).padStart(4, "0");
      if (this.$route.params.id !== siteID) {
          var page = this.$route.params.page;
          this.$router.push({ path: "/installations/"+siteID+"/"+page, query: {'compare': this.$route.query.compare}});
          this.siteData.installationId = siteID;    // needed in case of failed API load
          API.transact("/installations/"+siteID+"/"+page, this.fieldsLoaded);
      }
    },

    menubackground(page) {
        if (this.currentPage === page) {
            return "menuitem-selected"
        } else {
            return "menuitem-deselected"
        }
    },

    saved(ok) {
      if (ok) {
        this.savebuttontext = "Changes saved";
        for (var field of this.fielddefs) {
            this.origFieldValue[field.xmlPath] = this.fieldvalue[field.xmlPath];
        }
      } else {
        this.savebuttontext = "Error while saving";
      }
      this.restoresavebuttonin(3000);
    },
    saveChangedValues() {
      var changed = {};
      var siteID = this.$route.params.id;
      var page   = this.$route.params.page;
      var isTestSite = (Number(siteID) <= 2);
      if (this.savebuttontimeout != null) {
        return;
      }
      var anyFieldChanged = false;
      for (var field of this.fielddefs) {
        if (isTestSite || this.fieldvalue[field.xmlPath] != this.origFieldValue[field.xmlPath] ) {
            console.log('changed '+field.xmlPath+' from '+this.origFieldValue[field.xmlPath] + " to " + this.fieldvalue[field.xmlPath]);
            var val = this.fieldvalue[field.xmlPath];
            if (field.inputType == 'date') {
                try {
                    val = this.fieldvalue[field.xmlPath].toISOString().split("T")[0];
                } catch {
                    val = "";
                }
                
            }
            if (field.inputType == 'checkbox') {
                if (val === false) { val = 0; }
                if (val === true ) { val = 1; }
            }
            changed[field.xmlFile + ':' + field.xmlPath] = val;
            anyFieldChanged = true;
        }
      }
      if (!anyFieldChanged) {
        this.savebuttontext = "No changes!";
        this.restoresavebuttonin(3000);
      } else {
        API.transact("/installations/save/"+siteID+"/"+page, this.saved, changed);
      }
    },
    restoresavebuttonin(milliseconds) {
      if (this.savebuttontimeout != null) {
        clearTimeout(this.savebuttontimeout);
      }
      this.savebuttontimeout = setTimeout(this.restoresavebutton, milliseconds);
    },
    restoresavebutton() {
      this.savebuttontimeout = null;
      this.savebuttontext = "Save changes";
    }
  },

  mounted() {
    var id = this.$route.params.id;
    this.currentPage = this.$route.params.page;
    API.transact("/installations/"+id+"/"+this.currentPage, this.fieldsLoaded);
    if (this.$route.query.compare) {
        API.transact("/installations/"+this.$route.query.compare+"/"+this.currentPage, this.compareLoaded);
    }
  }
}

</script>

<style scoped>

.pagemenu {
  text-align: center;
  margin-bottom: 10px;
}

.pagemenu-item {
  text-align: center;
  background-color: azure;
  display: inline-block;
  min-width: 120px;
  border: 1px solid black;
  border-radius: 4px;
  margin-right: 2px;
  padding: 4px;
}

.menuitem-selected {
  color: white;
  background-color: #0f2050;
}

.siteinfo {
    color: #0f2050;
    margin: 5px auto;
/*     text-align: center; */
    font-size: 20px;
}

.siteinfo div {
    display: inline-block;
}

.table {}
.row { height: 28px; }
.label { width: 300px; font-size: 15px; }
.field { width: 300px; font-size: 15px; }
.input { width: 290px; font-size: 15px; }
.readonly { background-color: lightgrey; }

button:disabled { background-color: #0f2050; color: white; }

</style>
