<template>
    <table class="user">
        <User header="yes" />
        <User
        v-for="u in users"
        header="no"
        :user=u
        :key="u.id"
        @deleteUser="this.deleteUser"
        />
    </table>
</template>

<script>
    import { API } from '../API.js'
    import User from './User.vue'

    export default {
        name: 'UserPage',
        data() {
            return {
                users: [],
            }
        },
        components: {
            User
        },
        props: {
        },
        methods: {
            UserLoaded(status, object) {
                this.users = object.result;
                if (!status) {
                    alert("Unable to load users for some reason?");
                }
            },
            UserDeleted(status) {
                if (!status) {
                    alert("Something went wrong deleting the user, please reload");
                }
            },
            deleteUser(user) {
                if (confirm("Do you really want to delete this user?")) {
                    this.users.splice(this.users.indexOf(user), 1);
                    API.transact("/user/" + user.username, this.reload, "1", "DELETE");
                }
            },
        },
        mounted() {
            API.transact("/user", this.UserLoaded);
        },
        unmounted() {
            if (this.reloadtimer) {
                clearTimeout(this.reloadtimer);
                this.reloadtimer = null;
            }
        }
    }
</script>

<style scoped>

.user {
    margin-left: auto;
    margin-right: auto;
    table-layout: fixed;
    width: 1200px;
}
</style>
