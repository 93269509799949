import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import VCalendar from 'v-calendar';

import Header from './components/Header.vue'
import CertificationPage from './components/CertificationPage'
import Changelog from './components/Changelog'
import HardwareConfigure from './components/HardwareConfigure'
import InstallationPage from './components/InstallationPage'
import InstallationCreate from './components/InstallationCreate'
import InstallationEdit from './components/InstallationEdit'
import InstallationEvents from './components/InstallationEvents'
import InstallationStatus from './components/InstallationStatus'
import Login from './components/Login'
import Logout from './components/Logout'
import UserPage from './components/UserPage'
import UserEdit from './components/UserEdit'
import UserPasswordReset from './components/UserPasswordReset'
import UserCreate from './components/UserCreate'
import MeasurementPage from './components/MeasurementPage'
import RopehHistory from './components/RopeHistory'
import SchedulePage from './components/SchedulePage'
import UploadReference from './components/UploadReference'
import UploadMeasurement from './components/UploadMeasurement'
import UploadH5 from './components/UploadH5'

const routes = [
  {
    path: "/login", components: {
      default: Login,
      header: Header,
    },
    meta: {
      msg: 'Login',
      requiresAuth: false
    }
  },
  {
    path: "/logout", components: {
      default: Logout,
      header: Header,
    },
    meta: {
      msg: 'Logout',
      requiresAuth: false
    }
  },
  {
    path: "/", components: {
      default: InstallationPage,
      header: Header,
    },
    meta: {
      msg: 'Installation Overview',
      requiresAuth: true
    }
  },
  {
    path: "/installations", components: {
      default: InstallationPage,
      header: Header,
    },
    meta: {
      msg: 'Installation Overview',
      requiresAuth: true
    }
  },
  {
    path: "/installations/create", components: {
      default: InstallationCreate,
      header: Header,
    },
    meta: {
      msg: 'Create new installation',
      requiresAuth: true,
    }
  },
  {
    path: "/installations/newhardware", components: {
      default: HardwareConfigure,
      header: Header,
    },
    meta: {
      msg: 'Configure hardware to Installation ID',
      requiresAuth: true,
    }
  },
  {
    path: "/installations/:id/status", components: {
      default: InstallationStatus,
      header: Header,
    },
    meta: {
      msg: 'Device Status',
      requiresAuth: true,
    }
  },
  {
    path: "/installations/:id/events", components: {
      default: InstallationEvents,
      header: Header,
    },
    meta: {
      msg: 'Device Events',
      requiresAuth: true,
    }
  },
  {
    path: "/installations/:id/changelog", components: {
      default: Changelog,
      header: Header,
    },
    meta: {
      msg: 'Changelog',
      requiresAuth: true,
    }
  },
  {
    path: "/installations/:id/ropehistory", components: {
      default: RopehHistory,
      header: Header,
    },
    meta: {
      msg: 'Rope History',
      requiresAuth: true,
    }
  },
  {
    path: "/installations/:id/:page", components: {
      default: InstallationEdit,
      header: Header,
    },
    meta: {
      msg: 'Installation Edit',
      requiresAuth: true,
    }
  },
  {
    path: "/measurements", components: {
      default: MeasurementPage,
      header: Header,
    },
    meta: {
      msg: 'Measurement Overview',
      requiresAuth: true,
    }
  },
  {
    path: "/schedules", components: {
      default: SchedulePage,
      header: Header,
    },
    meta: {
      msg: 'Schedule Overview',
      requiresAuth: true,
    }
  },
  {
    path: "/certifications", components: {
      default: CertificationPage,
      header: Header,
    },
    meta: {
      msg: 'Certifications Overview',
      requiresAuth: true,
    }
  },
  {
    path: "/upload/reference", components: {
      default: UploadReference,
      header: Header,
    },
    meta: {
      msg: 'Upload Reference',
      requiresAuth: true,
    }
  },
  {
    path: "/upload/measurement", components: {
      default: UploadMeasurement,
      header: Header,
    },
    meta: {
      msg: 'Upload Measurement',
      requiresAuth: true,
    }
  },
  {
    path: "/user", components: {
      default: UserPage,
      header: Header,
    },
    meta: {
      msg: 'User Overview',
      requiresAuth: true
    }
  },
  {
    path: "/user/:username", components: {
      default: UserEdit,
      header: Header,
    },
    meta: {
      msg: 'User Edit',
      requiresAuth: true
    }
  },
  {
    path: "/user/:username/resetpw", components: {
      default: UserPasswordReset,
      header: Header,
    },
    meta: {
      msg: 'User Password Reset',
      requiresAuth: true
    }
  },
  {
    path: "/user/create", components: {
      default: UserCreate,
      header: Header,
    },
    meta: {
      msg: 'User Create',
      requiresAuth: true
    }
  },
  {
    path: "/upload/h5file", components: {
      default: UploadH5,
      header: Header,
    },
    meta: {
      msg: 'Upload H5 File',
      requiresAuth: true
    }
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes, // short for `routes: routes`
})


router.beforeEach((to, from, next) => {
  if (!to.meta.requiresAuth) { // per default expect route to be protected
    next()
  } else {
    const token = localStorage.getItem('token');
    if (token) {
      // User is authenticated, proceed to the route
      next();
    } else {
      // User is not authenticated, redirect to login
      next('/login');
    }
  }
});


createApp(App)
  .use(router)
  .use(VCalendar)
  .mount('#app');
