<template>

    <div class="siteinfo">
        <div class="id">{{siteData.installationId}}</div> -
        <div class="country">{{siteData.country}}</div> - 
        <div class="city">{{siteData.city}}</div> - 
        <div class="customer">{{siteData.customer}}</div> - 
        <div class="instname">{{siteData.instname}}</div>
    </div>

    <table>
        <tr v-for="row in changelog" :key=row.join()>
            <td class="commitdate" v-if="row[0] == 'commitinfo'">{{row[1]}}</td>

            <td v-if="row[0] == 'commitmessage'"></td>
            <td class="commitmessage" colspan=2 v-if="row[0] == 'commitmessage'">{{row[1]}}</td>

            <td class="changelogfield"  v-if="row[0] == 'changelogentry'">{{row[1]}}</td>
            <td class="changelogold"    v-if="row[0] == 'changelogentry'">{{row[2]}}</td>
            <td class="changelognew"    v-if="row[0] == 'changelogentry'">{{row[3]}}</td>
        </tr>
    </table>

</template>

<script>
import { API } from '../API.js'

export default {
  name: 'InstallationPage',
  data() {
    return {
      siteData:  {},
      changelog: [],
    }
  },
  methods: {
    fieldsLoaded(status, object) {
      if (!status) { 
        this.siteData = {};
      } else {
        this.siteData = object.result.instData;
      }
    },
    logLoaded(status, object) {
      if (!status) { 
        this.changelog = [];
      } else {
        this.changelog = object.result;
      }
    }
  },
  mounted() {
    var id = this.$route.params.id;
    API.transact("/installations/"+id+"/none", this.fieldsLoaded);
    API.transact("/installations/"+id+"/changelog", this.logLoaded);
  }
}

</script>

<style scoped>

.siteinfo {
    color: #0f2050;
    margin: 5px auto;
/*     text-align: center; */
    font-size: 20px;
}

.siteinfo div {
    display: inline-block;
}

.commitdate {
    font-size: 16px;
    font-weight: bold;
}

.commitmessage {
    font-size: 16px;
    font-style: italic;
}

.changelogentry {
    width: 300px;
}

.changelogold {
    background-color: #c08080;
}

.changelognew {
    background-color: #80c080;
}

</style>