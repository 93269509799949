<template>
    <div class="siteinfo">
      <div class="id">{{siteData.installationId}}</div> -
      <div class="country">{{siteData.country}}</div> - 
      <div class="city">{{siteData.city}}</div> - 
      <div class="customer">{{siteData.customer}}</div> - 
      <div class="instname">{{siteData.instname}}</div>
    </div>
    <hr>
    <div class="siteinfo">
      Status
    </div>

    <table>
      <tr>
          <td class="label">Age</td>
          <td class="field">{{humanReadableAge(status.dateInfo.unixTimestamp)}}</td>
        </tr>
        <tr>
          <td class="label">Timestamp (UTC)</td>
          <td class="field">{{status.dateInfo.utcDateTime}}</td>
        </tr>
        <tr>
          <td class="label">Timestamp (Server)</td>
          <td class="field">{{status.dateInfo.serverDateTime}}</td>
        </tr>
        <tr>
          <td class="label">Timestamp ({{status.dateInfo.usedInstallationZoneName}})</td>
          <td class="field">{{status.dateInfo.installationDateTime}}</td>
        </tr>
        <tr>
          <td colspan=2><hr></td>
        </tr>
        <tr>
          <td class="label">Device Status</td>
          <td class="field">{{status.controlProgramState}}</td>
        </tr>
        <tr>
          <td class="label">Software version (Control)</td>
          <td class="field">{{status.softwareVersionControl}}</td>
        </tr>
        <tr>
          <td class="label">Software version (Collector)</td>
          <td class="field">{{status.softwareVersionCollector}}</td>
        </tr>
        <tr>
          <td colspan=2><hr></td>
        </tr>
        <tr>
          <td class="label">Rope speed</td>
          <td class="field">{{status.ropeSpeed}}</td>
        </tr>
        <tr>
          <td class="label">Resistances</td>
          <td class="field">{{status.resistanceA}} / {{status.resistanceB}}</td>
        </tr>
        <tr>
          <td colspan=2><hr></td>
        </tr>
        <tr>
          <td class="label">Outstanding Measurement</td>
          <td v-if="status.pendingRequest == undefined || status.pendingRequest.pendingSince == 0" class="field">None</td>
          <td v-else class="field">{{status.pendingRequest.pendingReason}}, for {{Math.floor( (Date.now() - status.pendingRequest.pendingSince) / 60000 )}} minutes</td>
        </tr>
    </table>
    
    <hr>
    <div class="siteinfo">
      Pending transfers from Server to TruScan
      <table>
        <tr v-for="transfer in this.pending.filter(x => !x.directionToServer)" :key=transfer.filePath>
          <td class="filename">{{transfer.filePath}}</td>
          <td class="transfertime">{{this.age(transfer)}}</td>
        </tr>
      </table>
    </div>

    <hr>
    <div class="siteinfo">
      Pending transfers from TruScan to Server
      <table>
        <tr v-for="transfer in this.pending.filter(x => x.directionToServer)"
           :key=transfer.filePath>
          <td class="filename">{{transfer.filePath}}</td>
          <td class="transfertime">{{this.age(transfer)}}</td>
        </tr>
      </table>
    </div>

    <hr>
    <div class="siteinfo" v-if="transferStatus != ''">
      Current transfer status
      <table>
        <tr>
          <td class="transferstatus">{{transferStatus}}</td>
        </tr>
      </table>
    </div>

</template>

<script>
import { API } from '../API.js'

export default {
  name: 'InstallationStatus',
  data() {
      return {
          siteData: {},
          status: { dateInfo: {}},
          pending: [],
          transferStatus: "",
          timeout: null,
      }
  },
  methods: {
    age(transfer) {
      var seconds = new Date().getTime() / 1000 - transfer.pendingTime;
      if (seconds < 3600) {
        return "waiting for " + Math.floor(seconds / 60) + " minutes";
      } else if (seconds <= 100000) {
        return "waiting for " + Math.floor(seconds / 3600) + " hours";
      } else {
        return "waiting for " + Math.floor(seconds / 86400) + " days";
      }
    },
    fieldsLoaded(status, object) {
      if (!status) { 
        this.siteData = {};
      } else {
        this.siteData = object.result.instData;
      }
    },
    statusLoaded(status, object) {
      if (!status) {
        this.status = { dateInfo: {}};
      } else {
        this.status = object.result;
      }
    },
    pendingLoaded(status, object) {
      if (!status) {
        this.pending = [];
      } else {
        this.pending = object.result;
      }
    },
    transferStatusLoaded(status, object) {
      this.transferStatus = object.result;
    },
    reload(id) {
      API.transact("/installations/"+id+"/none", this.fieldsLoaded);
      API.transact("/installations/"+id+"/status", this.statusLoaded);
      API.transact("/transfers/"+id+"/pending", this.pendingLoaded);
      API.transact("/transfers/"+id+"/status", this.transferStatusLoaded);

      var self = this;
      this.timeout = setTimeout(function(){self.reload(id);}, 10000);
    },
    humanReadableAge(timestamp) {
      var now = Date.now() / 1000;
      var delta = now - timestamp;
      if (delta < 7200) {
        return Math.floor(delta / 60) + " minutes";
      }
      if (delta < 86400 * 2) {
        return Math.floor(delta / 3600) + " hours";
      }
      return Math.floor(delta / 86400) + " days";
    },
  },
  mounted() {
    var id = this.$route.params.id;
    this.currentPage = this.$route.params.page;
    this.reload(id);
  },
  beforeUnmount() {
    clearTimeout(this.timeout);
  }
}

</script>


<style scoped>

.siteinfo {
    color: #0f2050;
    margin: 5px auto;
/*     text-align: center; */
    font-size: 20px;
}

.siteinfo div {
    display: inline-block;
}

.table {}
.row { height: 28px; }
.label { width: 300px; font-size: 15px; }
.field { width: 300px; font-size: 15px; }
.filename { width: 600px; font-size: 15px; }
.transferstatus { font-size: 15px; }
.transfertime { width: 600px; font-size: 15px; }

</style>
