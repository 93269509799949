<template>
    <table class="upload">
        <tr>
            <th>Installation ID</th>
            <td>
                <VueMultiselect v-model="instid" :options="installationIDs" :custom-label="customLabel" @select="instidchanged"/>
            </td>
        </tr>
        <tr>
            <th>Device ID</th>
            <td>
                <input v-model="deviceid"/>
            </td>
        </tr>
        <tr>
            <th>H5 file</th>
            <td>
                <input type="file" class="inputfile" name="h5file"
                id="h5fileinput"
                accept=".h5,.trux"
                :disabled="!canUpload"
                @change="gotfile($event);">
                <div class="uploadarea">
                    <div class="uploadarea-text">
                        {{statustext()}}
                    </div>
                </div>
            </td>
            <td id="h5fileok"></td>
        </tr>
        <tr>
            <th></th>
            <td>
                <button :class="resetbuttonClass" v-on:click="resetHandler">
                    Reset
                </button>
            </td>
        </tr>
    </table>
</template>


<script>
import {API} from '../API.js';
import VueMultiselect from 'vue-multiselect'

export default {
    name: 'UploadH5',
    components: { VueMultiselect},
    data() {
        return {
            instid: "",
            h5Status: {
                progress: 0,
                filename: "",
            },
            instIdText: "Waiting for installation id",
            canUpload: false,
            installationIDs: [],
            installations: []
        }
    },
    computed: {
        h5FileOk() {
            return this.h5Status.progress == 100;
        },
        resetbuttonClass() { return this.instIdText === "Upload Done" ? "resethidden" : "resetshown" }
    },
    methods: {
        instidchanged() {
            if (this.instid.length == 0) {
                this.instIdText = "Waiting for installation id";
                this.canUpload = false;
            } else if (this.instid.length != 4
                || false    // todo check for digits
                ) {
                this.instIdText = "Installation ID needs 4 digits"
                this.canUpload = false;
            } else {
                this.instIdText = "Installation ID ok"
                this.canUpload = true;
            }
        },
        gotfile(event) {
            var filelist = event.target.files;
            if (filelist.length == 0) {
                console.log("have no files");
                this.setProgress(0);
                return;
            }
            if (!this.canUpload) {
                console.log("upload not enabled (bad installation id)");
                return;
            }
            var filename = filelist.item(0).name;
            console.log("file is " + filename + " now");
            this.getStatus().filename = filename;
            // problem; api assumes /api/v1 but we need /rotec/v1
            // use "portal" as rotec device id. This allows setting which devices can send
            // data through the portal.
            API.uploadHackForRotec("upload/" + this.deviceid + "/" + this.instid,
             (eventType, event) => this.uploadProgress(eventType, event),
              filelist.item(0));
            this.setProgress(1);
            this.instIdText = "Upload Done";
        },
        statustext() {
            if (!this.canUpload) {
                return this.instIdText;
            }
            var status = this.getStatus();
            switch (status.progress) {
                case 0: return "Drag a file here or click to choose"; 
                case 100: return "Upload of " + status.filename + " complete";
                case 101: return "Upload error";
                case 102: return "Upload aborted";
            }
            return "Uploading "+status.filename+" "+status.progress+" %"; // +": "+status.sent+" of "+status.total;
            
        },
        setProgress(status) {
            this.getStatus().progress = status;
        },
        getStatus() {
            return this.h5Status;
        },
        uploadProgress(eventType, event) {
            var status = this.getStatus();
            switch (eventType) {
                case 'progress': status.progress = Math.floor(event.loaded * 100 / event.total); break;
                case 'load':     status.progress = 100; break;
                case 'error':    status.progress = 101; break;
                case 'abort':    status.progress = 102; break;
            }
            console.log("event of "+eventType, event);
        },
        sentDone(ok, result) {
            if (result.status == 0) {
                this.buttonText = result.result;
            } else {
                this.buttonText = result.error;
            }
        },
        resetHandler() {
            document.getElementById("h5fileinput").value = null;
            this.h5Status.progress = 0;
        },
        InstallationsLoaded(status, object) {
            this.installationIDs = object.result.map(item => item.installationId)
            this.installations = object.result
            if (!status) {
                alert("Unable to load user for some reason?");
            }
        },
        customLabel(installationID) {
                return `${installationID} - ${this.installations.find((i) => {return i.installationId === installationID})?.instname} `
        }
    },
    mounted() {
        API.transact("/installations/", this.InstallationsLoaded);
    },
}
</script>


<style scoped>
th {
    text-align: left;
    width: 130px;
}

tr {
    min-height: 50px;
}

.uploadarea {
    outline: 2px dashed grey;
    outline-offset: -10px;
    text-align: center;
    vertical-align: middle;
    background-color:aqua;
    margin: 0;
    width: 500px;
    height: 100px;
}

.uploadarea:hover {
    background: cyan;
}

.uploadarea-text {
    width: 420px;
    height: 40px;
    padding: 40px;
}

.inputfile {
    opacity: 0;
    width: 500px;
    height: 100px;
    position: absolute;
    cursor: pointer;
}

button.filesok {
    width: 200px;
    height: 40px;
}

button.resethidden {
    width: 100px;
    height: 40px;
    visibility: hidden;
}

button.resetshown {
    width: 100px;
    height: 40px;
    visibility: visible;
}

::v-deep span.multiselect__tag {
background: #b3b6b6 !important;
color: #000000 !important;
}

::v-deep .multiselect__option--highlight {
    background: #b3b6b6 !important;
}


</style>