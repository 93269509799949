<template>
  <div class="page">
  <div>Create User: {{ user.username }}</div>
  <form @submit="submitChanges()">
    <table>
      <tr>
        <td><label class="label" for="username">Username: </label></td>
        <td><input required class="input" type="text" id="username" pattern="[A-Za-z0-9.-_@]+" title="username (only containing alphanumeric values and (@ . - _))"  v-model="user.username"></td>
      </tr>
      <tr>
        <td><label class="label" for="email">E-Mail: </label></td>
        <td><input required class="input" type="text" id="email" v-model="user.email"></td>
      </tr>
      <tr>
        <td><label class="label" for="name">Name: </label></td>
        <td><input required class="input" type="text" id="name" v-model="user.name"></td>
      </tr>
      <tr>
        <td><label class="label" for="password">Password: </label></td>
        <td><input required class="input" type="password" id="password" v-model="user.password"></td>
      </tr>
    </table>

    <button type="submit" class="row action">Submit</button>
    <div class="row">
        {{this.apierror}}
    </div>
  </form>
  </div>
</template>
<script>

    import { API } from '../API.js'

export default {
  name: 'User',
  props: {
  },
  data() {
    return {
      user: Object,
      apierror: ""
    }
  },
  methods: {
    UserCreated(status, object) {
      if (!status || !object.result.username) {
        if (status == 409) {
          alert("username already taken")
        } else {
          alert("Unable to create user for some reason?");
        }
      }
      else {
        this.$router.push("/user/");
      }

            },
            submitChanges() {
                API.transact("/user/", this.UserCreated, this.user);
            }
        },
        mounted() {
            this.user = {
                name: "",
                username: "",
                password: "",
                email: "",
                allowedInstallations: []
            };
        },
    }
</script>

<style scoped>
th,td {
    text-align: left;
    font-size: 15px;
    color:black;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

div.page {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
}

div.row {
    margin: auto;
    padding-top: 10px;
    height: 30px;
}

.label { width: 100px; }

.input { width: 200px; }

div.action {
    text-align: center;
    color: #2c3e50;
    background-color: azure;
    border: 1px solid black;
    border-radius: 4px;
    margin-right: 2px;
    padding: 4px;
    display: inline-block;
    height: 20px;
    width: 100px;
    cursor:pointer;
}
</style>
