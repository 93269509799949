<template>
    <table class="schedules">
        <Schedule header="yes" @sort="setSortCriterion" :sortorder=sortCriterion />
        <Schedule v-for="i in sortByCrit(schedules)"
            header="no"
            :key=i.id
            :schedule=i
            :installation=installations[i.id]
        />
    </table>
</template>

<script>
import {API} from '../API.js';
import Schedule from './Schedule.vue';

export default {
    name: 'SchedulePage',
    data() {
        return {
            schedules: [],
            installations: {},
            sortCriterion: "next"
        }
    },
    components: {
        Schedule
    },
    props: {

    },
    methods: {
        schedulesLoaded(status, object) {
            this.schedules = object.result;
        },
        installationsLoaded(status, object) {
            this.installations = {};
            for (var installation of object.result) {
                var id = installation.installationId;
                this.installations[id] = installation;
            }
        },
        setSortCriterion(crit) {
            this.sortCriterion = crit;
        },
        sortByCrit(schedules) {
            return schedules
            .filter((x) => { return x.nextMeasurement != ""; })
            .sort( (a, b) => {
                var comp;
                if (this.sortCriterion == 'next') {
                    if (a.nextMeasurement == b.nextMeasurement) {
                        return a.id.localeCompare(b.id);
                    }
                    // these two should not happen but leave them in in case we ever remove the filter
                    if (a.nextMeasurement == "")                return 1;
                    if (b.nextMeasurement == "")                return -1;
                    return (a.nextMeasurement.localeCompare(b.nextMeasurement));
                }
                if (this.sortCriterion == 'country') {
                    comp = this.installations[a.id].country.localeCompare(this.installations[b.id].country);
                }
                if (this.sortCriterion == 'instname') {
                    comp = this.installations[a.id].instname.localeCompare(this.installations[b.id].instname);
                }
                if (this.sortCriterion == 'remaining') {
                    comp = a.remaining - b.remaining;
                }
                if (comp != 0) { return comp; }
                // everything else, and identical keys, sort by installation id
                return (a.id.localeCompare(b.id));
            });
        }
    },
    mounted() {
        API.transact("/schedules", this.schedulesLoaded);
        API.transact("/installations", this.installationsLoaded);
    }
}
</script>
