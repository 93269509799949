<template>
    <div class="siteinfo">
        <div class="id">{{siteData.installationId}}</div> -
        <div class="country">{{siteData.country}}</div> - 
        <div class="city">{{siteData.city}}</div> - 
        <div class="customer">{{siteData.customer}}</div> - 
        <div class="instname">{{siteData.instname}}</div>
    </div>
    <hr>
    <div class="siteinfo">
      Rope history
    </div>

    <table>
        <tr class="row">
            <th>Rope Number</th>
            <th>Date created</th>
            <th>Actual<br>Lay Length</th>
            <th>Nominal<br>Lay Length</th>
            <th>Length</th>
            <th>Tolerance</th>
            <th>Diameter<br>(nominal)</th>
            <th>Diameter<br>(max. wire)</th>
            <th>Metallic<br>cross<br>section</th>
            <th>Outer<br>strands<br>(count)</th>
        </tr>

        <tr class="row" v-for="rope of history" :key="rope.ropeNumber" @click="this.jumptoedit()">
            <td class="int ropeid">{{rope.ropeNumber}}</td>
            <td class="date created">{{rope.creationDate}}</td>
            <td class="float laylength actuallaylength">{{rope.actualLayLength}}</td>
            <td class="float laylength nominallaylength">{{rope.nominalLayLength}}</td>
            <td class="float length">{{rope.length}}</td>
            <td class="float tolerance">{{rope.tolerance}}</td>
            <td class="float diameter">{{rope.nominalDiameter}}</td>
            <td class="float diameter">{{rope.maxWireDiameter}}</td>
            <td class="float crosssection">{{rope.metallicCSArea}}</td>
            <td class="int strands">{{rope.strands}}</td>
        </tr>
    </table>
    <hr>
    <div class="action" @click="createrope()">Create a new rope</div>
</template>

<script>
import { API } from '../API.js'

export default {
  name: 'RopeHistory',
  data() {
      return {
          siteData: {},
          history: [],
        }
  },
  methods: {
    fieldsLoaded(status, object) {
      if (!status) { 
        this.siteData = {};
      } else {
        this.siteData = object.result.instData;
      }
    },
    historyLoaded(status, object) {
      console.log("status is "+status+", device status is "+object);
      if (!status) {
        this.history = [];
      } else {
        this.history = object.result;
      }
    },
    createrope() {
      API.transact("installations/"+this.siteData.installationId+"/newrope", this.ropeAdded);
    },
    ropeAdded(status, object) {
      if (!status) {
        console.log("Status "+status, object);
      } else {
        API.transact("/installations/"+this.siteData.installationId+"/ropehistory", this.historyLoaded);
      }
    },
    jumptoedit() {
      this.$router.push("/installations/"+this.siteData.installationId+"/Rope%20Data");
    }
  },
  mounted() {
    var id = this.$route.params.id;
    this.currentPage = this.$route.params.page;
    API.transact("/installations/"+id+"/none", this.fieldsLoaded);
    API.transact("/installations/"+id+"/ropehistory", this.historyLoaded);
  }
}
  
</script>

<style scoped>

.siteinfo {
    color: #0f2050;
    margin: 5px auto;
/*     text-align: center; */
    font-size: 20px;
}

.siteinfo div {
    display: inline-block;
}

.table {}
td,th { width: 90px; font-size: 15px; text-align: right;}
.row { height: 28px; }
.ropeid { font-size: 15px; }
.laylength { font-size: 15px; }

.int { text-align: right; }
.float { text-align: right; }

div.action {
  text-align: center;
  color: #2c3e50;
  background-color: azure;
  border: 1px solid black;
  border-radius: 4px;
  margin-right: 2px;
  padding: 4px;
  display: inline-block;
  height: 20px;
  width: 200px;
  cursor:pointer;
}

</style>
