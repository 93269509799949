<template>
    <div class="siteinfo">
        <div class="id">{{siteData.installationId}}</div> -
        <div class="country">{{siteData.country}}</div> - 
        <div class="city">{{siteData.city}}</div> - 
        <div class="customer">{{siteData.customer}}</div> - 
        <div class="instname">{{siteData.instname}}</div>
    </div>
    <hr>
    <div class="siteinfo">
      Events
    </div>
    <pre>{{eventsString}}</pre>
    <div v-if="haveAllRows == false">
        <button v-on:click="maximumDisplayRows += 100; calcRelevantLines();" >There is more</button>
    </div>
</template>

<script>
import { API } from '../API.js'

export default {
  name: 'InstallationEvents',
  data() {
      return {
          siteData: {},
          eventLog: [],
          haveAllRows : false,
          eventsString: "",
          maximumDisplayRows: 100
      }
  },
  methods: {
    siteDataLoaded(status, object) {
      if (!status) { 
        this.siteData = {};
      } else {
        this.siteData = object.result.instData;
      }
    },      
    eventsLoaded(status, object) {
      if (!status) { 
        this.eventLog = "";
      } else {
        this.eventLog = object.result;
      }
      this.calcRelevantLines();
    },
    calcRelevantLines() {
        var displayString = "";
        var rows = 0;
        this.haveAllRows = true;
        const dateLogRegex= new RegExp("^20\\d\\d-\\d\\d-\\d\\d \\d\\d:\\d\\d:\\d\\d");
        for (var i=this.eventLog.length-1; i>=0; i--) {

            var newText = this.eventLog[i];
            if (!dateLogRegex.test(newText)) {
                displayString = displayString + this.eventLog[i];
            } else {
                var message = newText.substring(20);
                var repetitions = 0;
                while (i>0 && this.eventLog[i-1].substring(20) == message) {
                    repetitions++;
                    i--;
                }
                displayString += newText;
                if (repetitions > 1) {
                    displayString += " ( message repeats "+repetitions+" times)";
                } 
            }
            displayString += "\n";
            if (++rows > this.maximumDisplayRows) {
                this.haveAllRows = false;
                break;
            }
        }
        this.eventsString = displayString;
    }
  },
  mounted() {
    var id = this.$route.params.id;
    this.currentPage = this.$route.params.page;
    API.transact("/installations/"+id+"/none", this.siteDataLoaded);
    API.transact("/installations/"+id+"/eventlog", this.eventsLoaded);
  }
}

</script>
<style scoped>

.siteinfo {
    color: #0f2050;
    margin: 5px auto;
/*     text-align: center; */
    font-size: 20px;
}

.siteinfo div {
    display: inline-block;
}

</style>