<template>
    <div class="page">
        <div class="row">
            <div class="action" @click="logout()">Logout?</div>
        </div>
    </div>
</template>


<script>
    export default {
        name: "Logout",
        props: {
        },
        data() {
        },
        methods: {
            logout() {
                localStorage.clear();
                this.$router.push({path:"login"})
            }
        },
    }
</script>

<style scoped>

div.page {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
}

div.action {
    text-align: center;
    color: #2c3e50;
    background-color: azure;
    border: 1px solid black;
    border-radius: 4px;
    margin-right: 2px;
    padding: 4px;
    display: inline-block;
    height: 20px;
    width: 100px;
    cursor:pointer;
}

div.row {
    margin: auto;
    padding-top: 10px;
    height: 30px;
}

</style>
