<template>
  <grid class="container">
    <grid class="gridheader">
      <router-view name="header" />
    </grid>
    <grid class="gridnav">
      <Menu v-if='this.$route.path !== "/login"' />
    </grid>
    <grid class="gridmain">
      <router-view/>
    </grid>
  </grid>
</template>

<script>

import Menu from './components/Menu.vue'
// import Header from './components/Header.vue'
// import InstallationPage from './components/InstallationPage.vue'

export default {
  name: 'App',
  components: {
    Menu, // Header, InstallationPage
  },
}
</script>

<style>
#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 20px;
}

.container {
  display: grid;
  grid-template-columns: [first] minmax(210px,220px) [separator] 10px [line2] auto [line3] minmax(100px,auto) [end];
  grid-template-rows:  minmax(90,95)  auto  auto  auto ;
  
  background-color: azure;
  column-gap: 5 px;
  row-gap: 5px;
  border-radius: 8px;
}

.gridnav {
  grid-column-start: 1;
  grid-column-end: separator;
  grid-row-start: 1;
  grid-row-end: 5;
  background-color: #0f2050;
  border-radius: 8px;
  place-self: align left ;
  column-gap: 8px;
}

.gridheader{
  grid-column-start: line2;
  grid-column-end:  line3;
  grid-row-start: 1;
  grid-row-end: 1;
  text-align: center;
  background-color: transparent;
}

.gridmain {
  grid-column-start: line2;
  grid-column-end: line3;
  grid-row-start:2;
  grid-row-end: 2;
  background-color: lightgray;
  border-radius: 8px;
}

</style>
