<template>
  <div class="header">
    {{$route.meta.msg}}
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: [ 'msg' ],
  mounted() {
  }
}
</script>

<style scoped>
div.header {
  color: #0f2050;
  text-align: center;
  font-size: 24px;
  height: 30px;
}
</style>
