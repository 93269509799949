<template>
<tr class="installationmain" v-on:click.stop="expand();">
  <td v-if="header == 'no'" class="id">{{installation.installationId}}</td>
  <td v-if="header == 'no'" class="country">{{installation.country}}</td>
  <td v-if="header == 'no'" class="city">{{installation.city}}</td>
  <td v-if="header == 'no'" class="name">{{installation.instname}}</td>
  <td v-if="header == 'no'" class="status">{{getStatusDisplayString(installation.status)}}</td>

  <th v-if="header == 'yes'" class="id">Inst</th>
  <th v-if="header == 'yes'" class="country">Country</th>
  <th v-if="header == 'yes'" class="city">City</th>
  <th v-if="header == 'yes'" class="name">Installation</th>
  <th v-if="header == 'yes'" class="status">Status </th>
</tr>
<tr class="installationdetail" v-if="header == 'no' && installation.installationId === expanded">
  <td class="large" colspan="5">
    <div class="action" @click.stop="startedit(installation.installationId)">Edit</div>
    <div class="action" @click.stop="showstatus(installation.installationId)">View Status</div>
    <div class="action" @click.stop="showevents(installation.installationId)">Event Log</div>
    <div class="action" @click.stop="showchangelog(installation.installationId)">Change Log</div>
    <div class="action" @click.stop="showropehistory(installation.installationId)">Rope History</div>
    <div class="action" @click.stop="getofflineconfig(installation.installationId)">USB config</div>
  </td>
</tr>
<tr class="installationdetail" v-if="header == 'no' && installation.installationId === expanded">
  <td class="large" colspan="3">
    <div class="action orange" @click.stop="measure(installation.installationId)">{{ this.canstartmea ? "Start Mea" : "started"}}</div>
    Mea reason: <input v-model="meareason">
  </td>
  <td class="large" colspan="2">
  Tunnel:
    <div class="display" :class="installation.tunnelStatus.connected ? 'green' : 'red'">{{ installation.tunnelStatus.connected ? "Connected" : "Disconnected"}}</div>
    <div class="action" @click.stop="toggletunnel(installation.installationId)" :class="this.tunnelrequested ? 'green' : 'red'">{{ this.tunnelrequested ? "Requested" : "Not Req."}}</div>
  </td>
</tr>
</template>
<script>

import { API } from '../API';

export default {
  name: 'Installation',
  props: {
    installation: Object,
    header: String,
    expanded: String
  },
  data() {
    return {
      tunnelrequested: false,
      meareason: "network",
      canstartmea: true,
    }
  },
  methods: {
    startedit(id) {
      this.$router.push("/installations/"+id+"/Customer");
      return true;
    },
    showstatus(id) {
      this.$router.push("/installations/"+id+"/status");
      return true;
    },
    showevents(id) {
      this.$router.push("/installations/"+id+"/events");
      return true;
    },
    showchangelog(id) {
      this.$router.push("/installations/"+id+"/changelog");
      return true;
    },
    showropehistory(id) {
      this.$router.push("/installations/"+id+"/ropehistory");
      return true;
    },
    getStatusDisplayString(state) {
      var display = state.controlProgramState;
      if (display === "Standby" && state.pendingRequest && state.pendingRequest.pendingSince > 0) {
        display = "Mea Req. "+Math.floor((Date.now() - state.pendingRequest.pendingSince)/60000)+" min";
        return display;
      }
      if (display.startsWith("MeaRope")) {
        display = display + " (" + state.ropeSpeed + " m/s)";
      }
      if (state.dateInfo && state.dateInfo.unixTimestamp > 0) {
        var delta = Date.now()/1000 - state.dateInfo.unixTimestamp;
        if (delta > 86400) {
          display += " (>" + Math.floor(delta/86400) + " days)";
        }
        else if (delta > 4 * 60 * 60) {
          display += " (>" + Math.floor(delta/3600) + " hours)";
        }
      }
      return display;
    },
    tunnelcallback(status, result) {
      console.log(status, result);
      if (status) {
        this.tunnelrequested = result.result.requested;
      }
    },
    toggletunnel(id) {
      API.transact("installations/"+id+"/tunnel/" + (this.tunnelrequested ? "off" : "on"), this.tunnelcallback, null);
    },
    expand() {
      if (this.header == "no") {
        this.$emit("expand", this.installation.installationId);
      }
    },
    measurecallback(status, result) {
      if (status) {
        this.canstartmea = false;
        result.result = "" + result.result;    // nop to keep linter happy
        setTimeout(() => {this.canstartmea = true}, 15000);
      }
    },
    measure(id) {
      if (this.canstartmea) {
        API.transact("installations/"+id+"/measure?reason="+this.meareason, this.measurecallback, null);
      }
    },
    getofflineconfig(id) {
      window.open(window.getApiUrl() + "configfile/"+id);
    }
  },
  mounted() {
    if (this.installation == null) {
      // we are in the header ...
      return;
    }
    this.tunnelrequested = this.installation.tunnelStatus.requested;
  },
  emits: [ 'expand' ]
}
</script>

<style scoped>
th,td {
  text-align: left;
  font-size: 15px;
  color:black;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

td.large {
  overflow:visible;
}

tr.installationmain {
  height: 30px;
  /* cursor: cell; */
}

tr.installationdetail {
  height: 60px;
  background-color: azure;
}

tr.installationdetail td {
  border-radius: 10px;
  border: 1px solid black;
  padding: 5px;
}

div.action {
  text-align: center;
  color: #2c3e50;
  background-color: azure;
  border: 1px solid black;
  border-radius: 4px;
  margin-right: 2px;
  padding: 4px;
  display: inline-block;
  height: 20px;
  width: 100px;
  cursor:pointer;
}

div.display {
  text-align: center;
  color: #2c3e50;
  background-color: azure;
  /* border: 1px solid black;
  border-radius: 4px; */
  margin-right: 2px;
  padding: 4px;
  display: inline-block;
  height: 20px;
  width: 100px;
}

input { width: 120px; }

.red { background-color: tomato !important; }
.orange { background-color: darkorange !important; }
.green { background-color: limegreen !important; }


.id { width: 100px; }
.country { width: 100px; }
.city { width: 150px; max-width: 150px;}
.name { width: 200px; }
.opstart { width: 50px; }
.opstatus { width: 50px; }
.status { width: 290px; }
</style>
